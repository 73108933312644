import { useFormContext, useWatch } from 'react-hook-form'
import { EndlessHolidayFormInputs } from '@modules/offers/endless-holiday/details'
import { EndlessHolidayProduct } from '@modules/offers/endless-holiday/models'
import Decimal from 'decimal.js'

export const PRICE_SURPLUS_WITHOUT_GASTRO = 200

interface Response {
  price: string
}

export const useEndlessHolidayProductPrice = (subscriptionProduct: EndlessHolidayProduct): Response => {
  const { control } = useFormContext<EndlessHolidayFormInputs>()
  const products = useWatch({ control, name: 'products' })

  const isAdded = products.some(product => product.product.id === subscriptionProduct.id)
  const hasGastroCard = products.some(product => product.related_product === subscriptionProduct.id)

  const priceToShow = subscriptionProduct.price_brutto

  const price =
    isAdded && !hasGastroCard ? new Decimal(priceToShow).plus(PRICE_SURPLUS_WITHOUT_GASTRO).toString() : priceToShow

  return { price }
}
