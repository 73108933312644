import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { FormProvider, useForm } from 'react-hook-form'
import { Accommodation } from '@modules/offers/subscription-with-benefit/details/variants/card/subscription-with-benefit-variants-card'
import { OfferDetailsLayout } from '@layouts/offer-details-layout'
import { RESERVATION_APP_URLS } from '@helpers/consts'
import { OfferDetailsBaseInfo } from '@modules/offers/common/details/offer-details-base-info'
import { OfferRuleLink } from '@modules/offers/common/details/offer-rule-link'
import { EndlessHolidayAdditionalCostsInfo } from '@modules/offers/endless-holiday/details/endless-holiday-additional-costs-info'
import { EndlessHolidayPersonalData } from '@modules/offers/endless-holiday/details/endless-holiday-personal-data'
import { EndlessHolidayProduct, EndlessHolidayProductOption } from '@modules/offers/endless-holiday/models'
import { Invoice } from '@models/client'
import { EndlessHolidaySelection } from '@modules/offers/endless-holiday/details/endless-holiday-selection'
import { EndlessHolidayPayment } from './endless-holiday-payment'
import { EndlessHolidaySummaryStickyLabel } from '@modules/offers/endless-holiday/details/summary/summary-sticky-label'

export interface EndlessHolidayFormOption {
  price: string | number
  kind: EndlessHolidayProductOption
}

export interface EndlessHolidayFormInputProduct {
  product: EndlessHolidayProduct
  amount: number
  related_product: number | null
  options: EndlessHolidayFormOption[]
}

export interface EndlessHolidayFormInputs extends Invoice {
  products: EndlessHolidayFormInputProduct[]
  accommodation: Accommodation
  first_name: string
  last_name: string
  create_account: boolean
  new_account_password: string
  email: string
  product_package_endless_holiday_rules: boolean
}

export const EndlessHolidayView = (): React.ReactNode => {
  const resortGalleryRef = React.useRef<HTMLDivElement>(null)
  const summaryRef = React.useRef<HTMLDivElement>(null)

  const methods = useForm<EndlessHolidayFormInputs>({ defaultValues: { products: [], accommodation: 'apartment' } })

  const handleScrollToResortsGallery = () => {
    resortGalleryRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const handleScrollToSummary = () => {
    summaryRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <FormProvider {...methods}>
      <OfferDetailsLayout
        questions={FAQ_QUESTIONS}
        ref={resortGalleryRef}
        resortGalleryTitle="Gdzie wykorzystać Vouchery?"
      >
        <OfferDetailsBaseInfo
          images={SLIDER_IMAGES}
          benefits={BENEFITS}
          title="Wakacje w nieskończoność"
          description={
            <p>
              Wybierz Wakacje w Nieskończoność i odwiedź nas na najlepszych możliwych warunkach. Planuj urlop{' '}
              <span className="text-nowrap">z wyprzedzeniem </span> i ciesz się wyjątkowymi cenami za 6-dniowy pobyt.
              Skorzystaj i roześlij znajomym!
            </p>
          }
        >
          <EndlessHolidayAdditionalCostsInfo />
          <div className="d-flex flex-wrap">
            <OfferRuleLink keyword="product_package_endless_holiday_rules" title="Regulamin Wakacji w nieskończoność" />
            <IconWithText
              text="Gdzie wykorzystasz pakiety?"
              iconClassName="uil-map-marker font-xxxl me-2 lh-1"
              textClassName="font-lg lh-1"
              className="fw-semi-bold ms-sm-5 mt-3 mt-sm-0"
              onClick={handleScrollToResortsGallery}
            />
          </div>
        </OfferDetailsBaseInfo>
        <EndlessHolidaySelection />
        <EndlessHolidayPersonalData summaryRef={summaryRef} />
        <EndlessHolidayPayment />
      </OfferDetailsLayout>
      <EndlessHolidaySummaryStickyLabel onScrollToSummary={handleScrollToSummary} />
    </FormProvider>
  )
}

const BENEFITS = [
  '6-dniowe pobyty w domku lub w apartamencie',
  'Voucher na zakupy we wszystkich punktach gastronomicznych',
  '3 wersje kolorystyczne pakietów do wyboru',
  '7 ośrodków do wyboru (nad morzem i w górach)',
]

const SLIDER_IMAGES = [
  {
    original: require('@assets/images/offers/go-holiday/gallery/coffe.webp'),
    thumbnail: require('@assets/images/offers/go-holiday/gallery/coffe.webp'),
  },
  {
    original: require('@assets/images/offers/go-holiday/gallery/swimming-pool.webp'),
    thumbnail: require('@assets/images/offers/go-holiday/gallery/swimming-pool.webp'),
  },
  {
    original: require('@assets/images/offers/go-holiday/gallery/table-football.webp'),
    thumbnail: require('@assets/images/offers/go-holiday/gallery/table-football.webp'),
  },
  {
    original: require('@assets/images/offers/go-holiday/gallery/volley-ball.webp'),
    thumbnail: require('@assets/images/offers/go-holiday/gallery/volley-ball.webp'),
  },
  {
    original: require('@assets/images/offers/go-holiday/gallery/waterpark.webp'),
    thumbnail: require('@assets/images/offers/go-holiday/gallery/waterpark.webp'),
  },
  {
    original: require('@assets/images/offers/go-holiday/gallery/yoga.webp'),
    thumbnail: require('@assets/images/offers/go-holiday/gallery/yoga.webp'),
  },
]

const FAQ_QUESTIONS = [
  {
    question: 'Ile mam czasu na wykorzystanie zakupionego vouchera pobytowego?',
    answer:
      'Data ważności każdego vouchera pobytowego to 5 lat, licząc od wybranego roku. Przykładowo: Voucher od roku 2025 będzie ważny do końca 2029.',
  },
  {
    question: 'Ile mam czasu na wykorzystanie zakupionego vouchera gastronomicznego?',
    answer:
      'Voucher gastronomiczny będzie do wykorzystania w ciągu 3 lat od początku daty ważności kodu pobytowego. Przykładowo: Kupując voucher na pobyt od roku 2027, Twój voucher gastronomiczny będzie ważny do końca 2029 roku, do użycia na różnych rezerwacjach.',
  },
  {
    question: 'W jakich terminach mogę odbyć pobyty z Pakietu?',
    answer: (
      <>
        Terminy wykorzystania różnią się w zależności od wariantu oferty:
        <ul className="list-unstyled my-1">
          <li>Pakiet Brązowy - możesz do nas przyjechać od 01.01 do 31.05 oraz od 01.10 do 31.12*</li>
          <li>Pakiet Srebrny - możesz do nas przyjechać od 01.01 do 30.06 oraz od 01.09 do 31.12*</li>
          <li>Pakiet Złoty - możesz do nas przyjechać od 01.01 do 07.07 oraz od 26.08 do 31.12</li>
        </ul>
        <span>*Z wyłączeniem terminów specjalnych.</span>
      </>
    ),
  },
  {
    question: 'Czym są terminy specjalne?',
    answer:
      'Są to terminy wyłączone z oferty Pakietów, takie jak okres Świąt Wielkanocy i Bożego Narodzenia, Nowego Roku, Ferii zimowych czy Majówki lub weekendu Bożego Ciała. Szczegółowy wykaz terminów specjalnych znajdziesz w regulaminie oferty.',
  },
  {
    question: 'W których resortach mogę wykorzystać vouchery?',
    answer:
      'Masz aż wszystkie 7 resortów do wyboru. Dodatkowo w: Pobierowie, Rowach i Uzdrowisku Cieplice Zdrój czekamy na Ciebie przez cały rok!',
  },
  {
    question: 'Czy muszę decydować się na zakup vouchera gastronomicznego?',
    answer:
      'Nie, możesz zrezygnować z tej opcji. Jednak decydując się na zakup bez vouchera, cena pobytu wzrasta o 200 zł. Zachęcamy do zakupu vouchera gastronomicznego za 2 000 zł, dzięki czemu otrzymasz dostęp do lokali specjalnych!',
  },
  {
    question: 'Czym są lokale specjalne?',
    answer:
      'Lokale oznaczone na naszych mapkach kolorem fioletowym. Dostępne są jedynie w ramach ofert specjalnych, takich jak Wakacje w nieskończoność z voucherem gastronomicznym o wartości 2 000 zł. Rezerwacje w lokalach specjalnych (fioletowych) odbywają się na zasadach ustawionych turnusów pobytowych.',
  },
  {
    question: 'Czy muszę założyć rezerwację od razu przy zakupie Pakietu?',
    answer:
      'Nie. Nie wymagamy od Ciebie deklaracji terminów od razu. Otrzymasz zakupione vouchery (kody promocyjne), które następnie użyjesz przy zakładaniu rezerwacji.',
  },
  {
    question: 'Jak mogę zapłacić za pakiet i ile mam czasu?',
    answer: 'Korzystamy z bezpiecznych płatności Przelewy24. Sam wybierz wygodną dla siebie metodę płatności.',
  },
  {
    question: 'Kiedy otrzymam dostęp do zakupionego Pakietu?',
    answer:
      'Potwierdzenie e-mail wraz z listą voucherów otrzymasz od razu po zaksięgowaniu środków na naszym koncie bankowym. Cały proces zakupu może zająć zaledwie kilka minut!',
  },
  {
    question: 'Jak mogę założyć rezerwację? ',
    answer: (
      <section>
        Zachęcamy do skorzystania z wygodnego systemu rezerwacyjnego na naszej stronie internetowej pod adresem{' '}
        <a className="text-primary fw-bold text-decoration-none" href={RESERVATION_APP_URLS.MAIN_PAGE} target="_blank">
          rezerwuj.holidaypark.pl
        </a>
      </section>
    ),
  },
]
