import * as React from 'react'
import { BaseGastroCardAmountInput } from '@modules/offers/common/base-gastro-card-amount/base-gastro-card-amount-input'
import { formatPercentage, formatPriceShort } from '@helpers/price-helper'
import { useFormContext, useWatch } from 'react-hook-form'
import { useGastroCardProduct } from '@modules/offers/gastro-card-boost/details/use-gastro-card-product'
import * as R from 'ramda'
import { compareAsc, isBefore, parseISO } from 'date-fns'
import { formatDate } from '@helpers/date-formatter'
import { GastroCardMultiplier } from '@models/app-state'
import Decimal from 'decimal.js'
import { useGastroDynamicMultipliers } from '@modules/offers/gastro-card/dynamic/details/use-gastro-dynamic-multipliers'
import { GastroCardDynamicHigherBonusUpgradeButton } from '@modules/offers/gastro-card/dynamic/details/gastro-card-dynamic-higher-bonus-button'

interface Props {
  onScrollToDiscountsTable: () => void
}

export const BaseGastroCardDynamicAmountBox = ({ onScrollToDiscountsTable }: Props): React.ReactNode => {
  const { nextOffer } = useGastroDynamicMultipliers()

  const { control } = useFormContext()
  const amount = useWatch({ control, name: 'declared_sell_price' })

  const gastroCardProduct = useGastroCardProduct('gastro_card_dynamic')

  const timelineRef = React.useRef<HTMLDivElement>(null)
  const offerItemRef = React.useRef<HTMLDivElement>(null)
  const valueIndicatorRef = React.useRef<HTMLDivElement>(null)

  const availableOffers = (gastroCardProduct?.multipliers ?? []).filter(multiplier =>
    isBefore(new Date(), parseISO(multiplier.active_to)),
  )

  const sortedOffers = React.useMemo(
    () => availableOffers.sort((a, b) => Number(a.minimal_amount) - Number(b.minimal_amount)),
    availableOffers,
  )

  const offers = R.groupBy(R.prop('minimal_amount'), sortedOffers) as Record<string, GastroCardMultiplier[]>

  React.useEffect(() => {
    if (!timelineRef.current || !valueIndicatorRef.current || !offerItemRef.current) return

    const offerRanges = Object.keys(offers)
    const singleItemWidth = offerItemRef.current.scrollWidth

    const updatePosition = (
      value: number,
      max: number,
      valueIndicatorOfferStep: number = 1,
      valueIndicatorAmount: number,
    ) => {
      timelineRef.current!.style.transform = `translateX(-${singleItemWidth * (valueIndicatorOfferStep - 1)}px)`
      valueIndicatorRef.current!.style.transform = `translateX(${
        1 + ((valueIndicatorAmount - value) * (singleItemWidth - 1)) / (max - value)
      }px)`
    }

    const minimalValue = Number(sortedOffers[0].minimal_amount)
    const maximalValue = Number(sortedOffers.at(-1)?.minimal_amount ?? 2000) + 500

    offerRanges.forEach((rangeAmount, index) => {
      const nextOfferAmount = offerRanges[index + 1]

      const isInRange =
        new Decimal(amount).gte(rangeAmount) && (!nextOfferAmount || new Decimal(amount).lt(nextOfferAmount))

      if (isInRange) {
        const value = Decimal.clamp(amount, minimalValue, maximalValue).toNumber()
        updatePosition(Number(rangeAmount), nextOfferAmount ? Number(nextOfferAmount) : maximalValue, index + 1, value)
        return
      }
    })
  }, [amount])

  return (
    <div className="gastro-card-boost__amount-box d-flex flex-column pb-0">
      <span className="text-darker-gray font-xxxl text-center d-block fw-semi-bold">Wpłacana kwota:</span>
      <div className="my-4">
        <BaseGastroCardAmountInput />
        {nextOffer && <GastroCardDynamicHigherBonusUpgradeButton amount={amount} nextOffer={nextOffer} />}
      </div>
      <div className="gastro-card-dynamic__amount-box__offer overflow-hidden">
        <div className="gastro-card-dynamic__amount-box__offer__progress" />
        <div
          className="gastro-card-dynamic__amount-box__offer__progress__value-indicator"
          ref={valueIndicatorRef}
          style={{ transition: 'transform 100ms linear' }}
        />
        <div className="d-flex" ref={timelineRef} style={{ transition: 'transform 100ms linear' }}>
          {Object.entries(offers).map(([amount, data], index) => (
            <Item key={amount} ref={index === 0 ? offerItemRef : null} price={amount} data={data} />
          ))}
        </div>
      </div>
      <div className="border-top font-sm text-center py-2 mb-4 mb-md-0 gastro-card-dynamic__amount-box__offer__description-btn">
        <button className="btn-unstyled" onClick={onScrollToDiscountsTable}>
          <strong>Sprawdź</strong> dlaczego warto się spieszyć
        </button>
      </div>
    </div>
  )
}

const Item = React.forwardRef(
  ({ price, data }: { price: string; data: GastroCardMultiplier[] }, ref: React.Ref<HTMLDivElement>) => {
    const { getPercentageBonus } = useGastroDynamicMultipliers()

    const [activeMonth, ...months] = [...data].sort((a, b) =>
      compareAsc(parseISO(a.active_from), parseISO(b.active_from)),
    )

    return (
      <div className="gastro-card-dynamic__amount-box__offer__prices text-nowrap" ref={ref}>
        <strong className="text-dark font-xs">Od {formatPriceShort(price)}</strong>

        <span className="font-xs d-block fw-semi-bold">
          Bonus {formatDate(activeMonth.active_from, 'LLLL')}{' '}
          <span className="text-primary">{formatPercentage(getPercentageBonus(activeMonth))}</span>
        </span>

        <div className="my-2">
          {months.map(month => (
            <span className="font-xxs d-block fw-semi-bold" key={month.active_from}>
              {formatDate(month.active_from, 'LLLL')}{' '}
              <span className="text-primary">{formatPercentage(getPercentageBonus(month))}</span>
            </span>
          ))}
        </div>
      </div>
    )
  },
)
