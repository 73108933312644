import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { OfferDetailsRow } from '@modules/offers/common/details/offer-details-row'
import { FormError } from '@components/controls/form-error'
import { EndlessHolidayProductsVariantsYears } from '@modules/offers/endless-holiday/details/products-selection/product-variant/product-variants-years'
import { useAppSelector } from '@store/index'
import { selectAppData } from '@store/slices/app-slice'

export const EndlessHolidayProductVariants = (): React.ReactNode => {
  const { product_packages } = useAppSelector(selectAppData)
  const { formState } = useFormContext()

  const productSelectionRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (formState.errors.products_selection) {
      productSelectionRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [formState.errors.products_selection])

  if (!product_packages[0]?.products) {
    return null
  }

  return (
    <OfferDetailsRow
      title={
        <span className="text-secondary">
          <strong className="d-block">Wybierz swoje</strong> wakacje w nieskończoność
        </span>
      }
    >
      <div className="d-flex flex-wrap gap-3 w-100" ref={productSelectionRef}>
        <FormError inputName="products_selection" className="font-lg mt-n1 mb-3" />
        <EndlessHolidayProductsVariantsYears products={product_packages[0].products} />
      </div>
    </OfferDetailsRow>
  )
}
