import * as React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { Route, Routes, useLocation } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { HomeView } from '@modules/index'
import { SubscriptionWithBenefitView } from '@modules/offers/subscription-with-benefit/details'
import { NotFoundView } from './ts/404'
import { useDidUpdateEffect } from '@hooks/use-did-update-effect'
import { SubscriptionContractView } from '@modules/offers/subscription-contract/details'
import { CodesConversionView } from '@modules/offers/codes-conversion/details'
import { GoHolidayView } from '@modules/offers/go-holiday/details'
import { RulesView } from './ts/rules'
import { GastroCardDynamicView } from '@modules/offers/gastro-card/dynamic/details'
import { GastroCardView } from '@modules/offers/gastro-card/normal/details'
import { EndlessHolidayView } from '@modules/offers/endless-holiday/details'

export const AnimatedRouter = (): JSX.Element => {
  const location = useLocation()

  useDidUpdateEffect(() => {
    window.scroll({ top: 0, behavior: 'instant' })
  }, [location])

  return (
    <TransitionGroup>
      <CSSTransition classNames="page" timeout={200}>
        <Routes>
          <Route path={NavigationPath.Homepage} element={<HomeView />} />
          <Route path={NavigationPath.SubscriptionWithBenefits} element={<SubscriptionWithBenefitView />} />
          <Route path={NavigationPath.GastroCardDynamic} element={<GastroCardDynamicView />} />
          <Route path={NavigationPath.GastroCard} element={<GastroCardView />} />
          <Route path={NavigationPath.GoHoliday} element={<GoHolidayView />} />
          <Route path={NavigationPath.SubscriptionContract} element={<SubscriptionContractView />} />
          <Route path={NavigationPath.CodeConversion} element={<CodesConversionView />} />
          <Route path={NavigationPath.EndlessHoliday} element={<EndlessHolidayView />} />
          <Route path={NavigationPath.Rules} element={<RulesView />} />
          <Route path="*" element={<NotFoundView />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  )
}
